body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(113, 113, 113);
}

body, html {
    height: 100%;
    margin: 0;
}

.app {
    min-height: 100%;
    font-family: Avenir,Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-size: cover;
}

#light {
    background-image: url(../public/background_dcm.svg);
}

#dark {
    background: #000000;
}

#light .box, #light .footer-box {
    background-color: hsla(0,0%,100%,0.5);
}

#dark .box, #dark .footer-box {
    background-color: #060b19;
}

#light .banner {
    background-image: url(../public/banner_light.png);
}

.connect-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

#dark .banner {
    background-image: url(../public/banner_dark.svg);
}

#light .return-address, #light .address-input, #light .add-network, #light .send-button-disabled, #light .back-button, #light .contribute-button, #light .send-button, #light .react-select__control{
    background-color: transparent;
    border: solid 1px #004fff;
}

#dark .return-address, #dark .address-input, #dark .add-network, #dark .send-button-disabled, #dark .back-button, #dark .contribute-button, #dark .send-button, #dark .react-select__control {
    background-color: transparent;
    border: solid 1px #004fff;
}

#light .copy-icon {
    content: url(../public/copy_light.png);
}

#dark .copy-icon {
    content: url(../public/copy_dark.png);
}

#light .font, #light .back-button, #light a, #light .contribute-button, #light .address-input > input, #light .send-button, #light .react-select__single-value {
    color: white;
}

#dark .font, #dark .back-button, #dark a, #dark .contribute-button, #dark .address-input > input, #dark .send-button, #dark .react-select__single-value {
    color: white;
}

@media screen and (max-width: 1010px) {
    .app {
        padding-top: 10%;
        padding-bottom: 10%;
    }
}